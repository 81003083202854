.ProjectCardTableViewFull {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.ProjectCardTableViewAuto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: calc(-50vw + 50%);
    overflow-y: auto;
    padding: 5px 5px;
}